<template>
    <title>ウェブアクセシビリティ</title>
    <v-container class="px-10">
    <h2>ウェブアクセシビリティ方針</h2>
    <p>日本産業規格JIS X 8341-3:2016「高齢者・障害者等配慮設計指針－情報通信における機器，ソフトウェア及びサービス－第3部：ウェブコンテンツ」に対応することを目標とし、アクセシビリティの確保と向上に取り組んでいます。
    </p>
    <hr/>
    <h2>対象範囲</h2>
    <p>横浜市コールセンター問合せフォーム（ <a href="https://www.shisei-cc-chat.city.yokohama.lg.jp">https://www.shisei-cc-chat.city.yokohama.lg.jp</a>）全体</p>
    <hr/>
    <h2>目標とする適合レベル及び対応度</h2>
    <p>JIS X 8341-3:2016のレベルAAに準拠することを目指します。</p>
    <br/>
    <p>※本方針における「準拠」という表記は、情報通信アクセス協議会のウェブアクセシビリティ基盤委員会による「<a href="https://waic.jp/docs/jis2016/compliance-guidelines/202104/ ">ウェブコンテンツのJIS X 8341-3:2016 対応度表記ガイドライン 2021年4月版</a>」で定められた表記によるものです。</p>
    <hr/>
    <h2>JISに基づく試験結果</h2>
    <p>試験期間：令和6年11月12日～令和6年11月15日</p>
    <br>

    <table>
      <thead>
        <tr>
          <th>細分箇条</th>
          <th>達成基準</th>
          <th>適合レベル</th>
          <th>適用</th>
          <th>結果</th>
          <th width="30%">注記</th></tr>
      </thead>
      <tbody>
        <tr><td class='center'>1.1.1</td><td>非テキストコンテンツの達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>1.2.1</td><td>音声だけ及び映像だけ（収録済み）の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.2.2</td><td>キャプション（収録済み）の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.2.3</td><td>音声解説又はメディアに対する代替コンテンツ（収録済み）の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.2.4</td><td>キャプション（ライブ）の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.2.5</td><td>音声解説（収録済み）の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.3.1</td><td>情報及び関係性の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>1.3.2</td><td>意味のある順序の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>1.3.3</td><td>感覚的な特徴の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.4.1</td><td>色の使用の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>1.4.2</td><td>音声の制御の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>1.4.3</td><td>コントラスト（最低限レベル）の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>△</td><td>共通デザインカラーに準拠</td></tr>
        <tr><td class='center'>1.4.4</td><td>テキストのサイズ変更の達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>1.4.5</td><td>文字画像の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>2.1.1</td><td>キーボードの達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.1.2</td><td>キーボードトラップなしの達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.2.1</td><td>タイミング調整可能の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>チャットはリアルタイム方式のため非該当</td></tr>
        <tr><td class='center'>2.2.2</td><td>一時停止，停止及び非表示の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>2.3.1</td><td>3回のせん（閃）光，又はしきい（閾）値以下の達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>2.4.1</td><td>ブロックスキップの達成基準</td><td>A</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>2.4.2</td><td>ページタイトルの達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.4.3</td><td>フォーカス順序の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.4.4</td><td>リンクの目的（コンテキスト内）の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.4.5</td><td>複数の手段の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>ー</td><td>本ページ（4ページ）内でのサイトマップは不要と判断した</td></tr>
        <tr><td class='center'>2.4.6</td><td>見出し及びラベルの達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>2.4.7</td><td>フォーカスの可視化の達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.1.1</td><td>ページの言語の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.1.2</td><td>一部分の言語の達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.2.1</td><td>フォーカス時の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.2.2</td><td>入力時の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.2.3</td><td>一貫したナビゲーションの達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.2.4</td><td>一貫した識別性の達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.3.1</td><td>エラーの特定の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.3.2</td><td>ラベル又は説明の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.3.3</td><td>エラー修正の提案の達成基準</td><td>AA</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>3.3.4</td><td>エラー回避（法的，金融及びデータ）の達成基準</td><td>AA</td><td class='center'>ー</td><td class='center'>ー</td><td>（該当するコンテンツなし）</td></tr>
        <tr><td class='center'>4.1.1</td><td>構文解析の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
        <tr><td class='center'>4.1.2</td><td>名前（name），役割（role）及び値（value）の達成基準</td><td>A</td><td class='center'>○</td><td class='center'>○</td><td></td></tr>
      </tbody>
    </table>


    <v-col class="text-center">
      <v-btn variant="outlined" @click="closeWindow">
        閉じる
      </v-btn>
    </v-col>

  </v-container>
</template>
  
<script>
  export default {
    name: 'AccessibilityPage',
    methods: {
      closeWindow() {
        window.close(); // ページを閉じる
      },
    },
  }
</script>
<style scoped>
  h2 {
    color:rgb(38, 90, 185);
    font-size: 1.2em;
    font-weight: bold;
  }
  
  hr {
    margin-top: 20pt;
    margin-bottom: 20pt;
  }

  table
  {
    width: 100%;
    border-collapse: collapse; 
  }

  th
  {
    background-color: rgb(38, 90, 185);
    color: white;
    border: 1pt solid rgb(38, 90, 185);
    text-wrap: nowrap;
    padding: 2pt;
  }

  td
  {
    color: darkslategrey;
    border-left: 0px solid white;
    border-right: 0px solid white;
    border-bottom: 1px solid black;
    text-wrap:  wrap;
    padding: 2pt;
  }

  .center
  {
    text-align: center;
  }
</style>