<template>
    <title>横浜市問合せ入力</title>
    <v-app id='app' v-cloak>
        <v-main class='container-fluid p-1 px-1'>
            <h1 class="title">{{ titles[currentStep] }}</h1>
            <FormWizard :on-complete="onComplete" 
                title="お問合せ送信フォーム" subtitle="必要事項を記入の上送信ボタンを押してください" 
                hide-buttons
                ref="formWizard" disable-back next-button-text="次へ" back-button-text="戻る" finish-button-text="閉じる"
                step-size="xs" color="darkblue" role="tablist">
                <TabContent id='1' title="申請内容の入力" role="tabpanel" aria-label="申請内容の入力" :before-change="beforeStepChange">
                    <Form ref="step1Form" :validation-schema="schema">
                        <v-container>
                            <v-row>
                                <v-col :class="DtHd">お名前</v-col>
                            </v-row>
                            <v-row>
                                <v-card elevation="0" class="w-50 text-start mb-4">
                                    <v-label :class="FdHd" for="lastname" id="labelLastname">姓</v-label>
                                    <Field label="姓" type='text'  name="lastname" color="primary" v-model="formField.LastName" aria-labelledby="labelLastname"/>
                                </v-card>
                                <v-card elevation="0" class="w-50 text-start mb-4">
                                    <v-label :class="FdHd" for="firstname" id="labelFirstname">名</v-label>
                                    <Field label="名" type='text' name="firstname" id="firstname" v-model="formField.FirstName" aria-labelledby="labelFirstname"/>
                                </v-card>
                            </v-row>
                            <v-row>
                                <v-col :class='DtHd' id="labelWard">お住いの区 <span class="require">必須</span> </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <Field id="Ward" name="Ward" as="v-select" variant="outlined" :items="wards" :rules="Ward"
                                        v-model="formField.Ward" aria-required="true" aria-labelledby="labelWard">
                                    </Field>
                                    <ErrorMessage name="Ward" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtHd'>メールアドレス <span class="require">必須</span> </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    スマートフォン・携帯電話のメールアドレスもこちらに入力してください。
                                    <br />
                                    ※メールの受信設定（ドメイン指定など）によっては、回答が届かない場合がありますので、city.yokohama.lg.jpを受信可能にしてください。
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <v-label :class="FdHd" for="Email" id="labelEmail">メールアドレス</v-label>
                                    <Field id="Email" name="Email" type='email'  aria-required="true" v-model="formField.Email" aria-labelledby="labelEmail"/>
                                    <ErrorMessage id="email" name="Email" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <v-label :class="FdHd" id="labelEmailConfirm" for="EmailConfirm">メールアドレス確認</v-label>
                                    <Field id="EmailConfirm" name="EmailConfirm" type='email'  aria-required="true" v-model="formField.EmailConfirm" aria-labelledby="labelEmailConfirm" />
                                    <ErrorMessage name="EmailConfirm" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtHd' id="labelSubject">件名<span class="require">必須</span> </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <Field id="Subject" name="Subject" type='text' :rules="EmailConfirm"
                                        aria-required="true" v-model="formField.Subject" aria-labelledby="labelSubject"/>
                                    <ErrorMessage name="Subject" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtHd' id="labelMailBody">お問合せ内容 <span class="require">必須</span> </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <Field id="MailBody" name="MailBody" as='v-textarea' height="200" variant="outlined"
                                        aria-required="true" v-model="formField.Body" aria-labelledby="labelMailBody" />
                                    <ErrorMessage name="MailBody" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtHd' id="labelNeedRes">回答について選択してください<span class="require">必須</span> </v-col>
                            </v-row>
                            <v-row>
                                <v-col :class='DtCt'>
                                    <Field id="NeedRes" name="NeedRes" as="v-select" variant="outlined" width="100pt"
                                        aria-required="true" v-model="formField.NeedRes" :items="needs" aria-labelledby="labelNeedRes">
                                    </Field>
                                    <ErrorMessage name="NeedRes" class="error" aria-invalid="true"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </Form>
                </TabContent>
                <TabContent title="申請内容の確認" role="tabpanel" aria-label="申請内容の確認">
                    <v-container>
                        <v-row>
                            <v-col :class='CmHd'>お名前</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                <div class=" d-flex flex-row">
                                    {{ this.formField.LastName === '' && this.formField.FirstName === '' ? '（未入力）' :
                                        this.formField.LastName + ' ' + this.formField.FirstName }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmHd'>お住いの区</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                {{ this.formField.Ward }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmHd'>メールアドレス</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                {{ this.formField.Email }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmHd'>件名</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                {{ this.formField.Subject }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmHd'>お問合せ内容</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                <pre>{{ this.formField.Body }}</pre>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmHd'>回答について選択してください</v-col>
                        </v-row>
                        <v-row>
                            <v-col :class='CmCt'>
                                {{ formField.NeedRes }}
                            </v-col>
                        </v-row>
                    </v-container>
                </TabContent>
                <TabContent title="申請の完了" role="tabpanel" aria-label="申請の完了">
                    <v-container>
                        <v-row>
                            <v-col>
                                <p class="message-text">申請を受け付けました。</p>
                                <br />
                                <p class="message-text">お問い合わせの際に必要となりますので、申込番号を控えるか、このページを印刷してください。
                                </p>
                                <p class="message-text">なお、自動返信メールにもお問い合わせ番号は記載されていますのでメールが届いているかも合わせてご確認ください。
                                </p>
                                <br />
                                <p v-if="formField.NeedRes === '必要'" class="message-text">処理状況については横浜市コールセンターへお問い合わせください。
                                </p>
                                <br />
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <v-card class="bordered-card px-10" outlined elevation="2">
                                    <v-card-title class="text-center font-std">申込番号</v-card-title>
                                    <v-card-text class="text-center">
                                        <p class="text-blue-darken-1 font-weight-bold font-std">{{ inquiryNumber }}</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </TabContent>
            </FormWizard>
            <v-container>
                <v-row v-if="message !== ''">
                    <v-col cols="12" class="text-center">
                        <v-label :class="messageType" aria-controls="alert-dialog">{{ message }}</v-label>
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col cols="12" class="text-center">
                        <v-btn class="mx-1" v-if="currentStep <= 1"  tabindex="0" variant="outlined" @click="prev" :disabled="sending" title="戻る" ref="retrunButton" >戻る</v-btn>
                        <v-btn class="mx-1" v-if="currentStep === 0" tabindex="0" variant="outlined" @click="next" title="次へ進む">次へ進む
                        </v-btn>
                        <v-btn class="mx-1" v-if="currentStep === 1" tabindex="0" variant="outlined" @click="next" :disabled="sending" title="申請する">
                            <v-progress-circular v-if="sending" size="30" width="5" color="green"
                                indeterminate></v-progress-circular>
                            {{ sendTitle }}
                        </v-btn>
                        <v-btn class="mx-1" v-if="currentStep === 2" tabindex="0" variant="outlined" to="/"
                            title="ホームに戻る">ホームに戻る</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title class="headline">確認</v-card-title>

                    <v-card-text>
                        申請します。よろしいですか？
                    </v-card-text>

                    <v-card-actions>
                        <!-- OKボタン -->
                        <v-btn color="primary" variant="outlined" @click="onConfirm">ＯＫ</v-btn>
                        <!-- キャンセルボタン -->
                        <v-btn variant="outlined" @click="onCancel">キャンセル</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>


<script>

import { ref } from 'vue';
import axios from "axios";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { FormWizard, TabContent } from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'

export default {
    components: {
        FormWizard,
        TabContent,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            Ward: '',
            Email: '',
            EmailConfirm: '',
            Subject: '',
            mBody: ''
        }
    },
    methods: {
        // ステップ移動前のバリデーションチェック
        next: async function () {
            switch (this.currentStep) {
                case 0:
                    break;
                case 1:
                    if (!this.confirm) {
                        this.dialog = true;
                        return;
                    } else {
                        this.dialog = false;
                        this.sending = true;
                        var res = await this.sendMail();
                        if (!res) {
                            this.sending = false;
                            return;
                        } else {
                            this.sending = false;
                        }
                    }
                    break;
                case 2:
                    window.location = "/";
                    return;
            }
            this.currentStep++;
            this.$refs.formWizard.nextTab();
            this.$refs.retrunButton.$el.focus();
        },
        prev: function () {
            if (this.currentStep <= 0) {
                window.location = "/";
                return;
            }

            this.message = "";
            this.messageType = "text-primary";

            this.currentStep--;
            this.$refs.formWizard.prevTab();
        },
        beforeStepChange: async function () {
            const isValid = await this.$refs.step1Form.validate();
            console.log("isValid:", isValid);
            if (!isValid.valid) {
                this.message = "入力内容が不正です。再度入力をお願いします。";
                this.messageType = "text-error";
                this.currentStep = 0;
                return false;
            } else {
                this.message = "";
                this.messageType = "text-primary";
                return true;
            }
        },
        onComplete: function () {
            window.location = "/";
        },
        onConfirm() {
            this.dialog = false;
            this.confirm = true;
            this.next();
        },
        onCancel() {
            this.dialog = false;
        },
        sendMail: async function () {
            console.log("sendMail:", this.formField);
            this.message = "";
            this.messageType = "message-text";

            this.sendTitle = "申請中…";
            const res = await this.sendForm(this.formField);
            this.sendTitle = "申請する";
            console.log("res:", res);
            if (res.status == 200) {
                this.inquiryNumber = res.data;
                return true;
            } else {
                console.log("res:", res);
                this.inquiryNumber = "";
                this.message = "送信に失敗しました。メールアドレスのお間違いがないかなどご確認ください。";
                this.messageType = "text-error";
                return false;
            }
        },
        sendForm: async function (sendData) {
            //var url = "/api/SendMail";
            //var url = "http://localhost:7071/api/SendMail";
            var url = process.env.VUE_APP_SENDMAIL_API_URL;

            console.log(url, ":", sendData);
            try {
                var res = await axios.post(url, JSON.stringify(sendData),
                    {
                        headers: {
                            //Authorization: 'Bearer ' + this.app_key,
                            'Content-Type': 'application/json'
                        }
                    });

                console.log("response:", res);

                return res;
            } catch (error) {
                console.log("sendData error:", error);
                return {
                    status: 400,
                    message: error.message
                }
            }
        }
    },
    setup() {
        const DtHd = ref('bg-grey-lighten-4 align-center pt-2 px-2 font-weight-bold text-grey-darken-3 font-std justify-center');
        const DtCt = ref('text-start font-std py-2');
        const FdHd = ref('align-left text-grey-darken-4 font-std px-2 opacity-1');
        const TbCt = ref('px-1 align-left text-grey-darken-1 text-wrap text-break font-std');
        const CmHd = ref('align-left pt-2 pb-0 px-2 font-weight-bold text-blue-darken-4 text-bold font-std');
        const CmCt = ref('text-start font-std pt-0 pb-2');

        let formWizard = ref(FormWizard);
        let currentStep = ref(0);
        let dialog = ref(false);
        let confirm = ref(false);
        let sending = ref(false);
        let message = ref('');
        let messageType = ref('');

        let headtitle = ref('申請内容の入力');
        let titles = ref(['申請内容の入力', '申請内容の確認', '申請の完了']);
        let sendTitle = ref('申請する');
        let inquiryNumber = ref('');

        let schema = ref(Object());
        schema = yup.object({
            Ward: yup.string().required('お住いの区は必須入力です'),
            Email: yup.string().required('メールアドレスは必須入力です').email('メールアドレスの書式が不正です。 例のような書式にしてください。例）sample@bbb.cc '),
            EmailConfirm: yup.string()
                .required('メールアドレス確認は必須入力です')
                .email('メールアドレスの書式が不正です')
                .oneOf([yup.ref("Email")], "メールアドレスと一致していません"),
            Subject: yup.string().required('件名は必須入力です'),
            MailBody: yup.string().required('お問合せ内容は必須入力です')
        });

        const formField = ref({
            LastName: '',
            FirstName: '',
            Ward: '',
            Email: '',
            EmailConfirm: '',
            Subject: '',
            Body: '',
            NeedRes: '不要',
        });

        const wards = ref([
            "青葉区",
            "旭区",
            "泉区",
            "磯子区",
            "神奈川区",
            "金沢区",
            "港南区",
            "港北区",
            "栄区",
            "瀬谷区",
            "都筑区",
            "鶴見区",
            "戸塚区",
            "中区",
            "西区",
            "保土ケ谷区",
            "緑区",
            "南区",
            "その他"
        ]);

        const needs = ref([
            "必要",
            "不要",
        ]);



        return {
            formWizard,
            formField,
            currentStep,
            dialog,
            confirm,
            sending,
            headtitle,
            titles,
            sendTitle,

            wards,
            needs,
            schema,
            message,
            messageType,
            inquiryNumber,
            DtCt, DtHd, TbCt, FdHd, CmHd, CmCt
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[v-cloak] {
    display: none;
}

body
{
    font-size: 10pt;
}

.title {
    color: white;
    background-color: darkblue;
    width: 100%;
    font-size: larger;
    padding: 5px;
    margin-bottom: 1em;
}

.error {
    color: #de0b30;
}

.require {
    color: white;
    background-color: #D50000;
    font-size: 0.8em;
    border-color: #D50000;
    padding: 2px;
    padding-right:5px;
    padding-left: 5px;
    border-radius: 5px;
    margin: 3pt;
}

.btn {
    border-color: #b7b7b7;
    background-color: #F8F9FA;
    color: #000000;
    line-height: 20px;
    margin-bottom: 4px;
}

.font-std {
    font-size: 1rem;
}

.table>:not(:first-child) {
    border-top: none
}

.message-text {
    color: black;
    font-size: 100%;
}

.bordered-card {
    margin: 0 auto;
    border: 1px solid black;
    padding: 16px;
}

.opacity-1 {
  opacity: 1;
}

input[type="text"],
input[type="email"] {
    background: none;
    -webkit-appearance: none;
    positon: relative;
    width: 99%;
    height: 38px;
    margin: 1px;
    padding: 0 1px;
    padding-left: 5px;
    border: 1px solid #b7b7b7;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 100%;
}

.v-container
{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.v-field__input
{
    font-size: 1em !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 1em !important;
    max-height: 1.2em !important;
}

.vue-form-wizard
{
    padding-bottom: 2px !important;
}

.vue-form-wizard *
{
    font-size: 1em !important;
}

</style>
