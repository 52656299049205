import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import HomePage from '@/views/HomePage.vue'
import AccessibilityPage from '@/views/AccessibilityPage.vue'
import InquiryPage from '@/views/InquiryPage.vue'

const routes = [
    {
        path: '/',
        component: DefaultLayout, // 共通レイアウト
        children: [
            {
                path: '',
                name: 'Home',
                component: HomePage,
            },
            {
                path: '/accessibility',
                name: 'Accessibility',
                component: AccessibilityPage,
            },
            {
                path: '/inquiry',
                name: 'Inquiry',
                component: InquiryPage,
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
