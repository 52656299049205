<template>
    <InquiryForm/>
</template>
  
<script>
import InquiryForm from '../components/InquiryForm.vue'

export default {
  name: 'InquiryPage',
  components: {
    InquiryForm, 
  },  
}
</script>
  