<template>
    <title>横浜市問合せフォーム</title>
    <v-container class="px-4">
    <v-col>
      <h2>概要</h2>
      <p>横浜市の窓口案内・各種手続きについてお尋ねになりたいことがございましたら、「問合せ内容」欄に記入してください。</p>
      <p>お問合せは、担当部署に対応を引き継ぐため、回答までに日数を要するので、お急ぎの際は担当課に直接お問い合わせ下さい。</p>
      <p>参考URL：<a class="word-wrap"
          href="https://www.city.yokohama.lg.jp/callcenter/call.html">横浜市コールセンター</a>
      </p>
      <hr />
      <h2>お問い合わせ先</h2>
      <p>横浜市コールセンター</p>
      <p>電話番号：045-664-2525</p>
      <p>受付時間：午前８時～午後９時（土日祝日を含む毎日）</p>
    </v-col>
    <hr />
    <v-col class="text-center">
      <v-btn title="次へ進む" variant="outlined" to="/inquiry">
        次へ進む
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>
<style scoped>

header
{
  padding: 0 !important;
}

h2 {
  color: rgb(38, 90, 185);
  font-size: 1.2em;
  font-weight: bold;
}

hr {
  margin-top: 20pt;
  margin-bottom: 20pt;
}

.word-wrap {
  word-wrap: break-word;
  /* 旧ブラウザ向け */
  overflow-wrap: break-word;
  /* モダンブラウザ向け */
}
</style>